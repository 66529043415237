

import Component, {mixins} from 'vue-class-component';
import {Emit, PropSync, Watch} from 'vue-property-decorator';

import {Branding} from '@/mixins/Branding';
// import HeroImage from '@/components_v2/intelligent/branding/__COMP_HeroImage.vue';

import {authStore} from '@/store/__STORE_auth';
import {userStore} from '@/store/__STORE_user';

@Component

/**
 * Intelligent component for Join Meeting, component manages the VMR search flow
 */
export default class JoinMeeting extends mixins(Branding) {
  name: 'Join Meeting';

  @PropSync('reloadRequired', {type: Boolean}) readonly __PROPreloadRequired : boolean
  @PropSync('error', {type: String, default: null}) readonly __PROPerror : string

  private webrtcAlias = '';

  /**
   *  disables fullscreen
   */
  @Emit('disable-fullscreen')
  __EMITdisableFullScreen() {}

  /**
   * Watch function to see whether the logged in user changes
   */
  @Watch('authUser', {deep: true})
  WatchForBranding() {
    this.applyBrandingIfAvailable();
  }

  /**
   * Fires when the view is mounted
   */
  mounted() {
    this.__EMITdisableFullScreen();
    this.applyBrandingIfAvailable();
    window.setTimeout(() => {
      const ref = this.$refs.webrtcAlias as any;
      ref.focus();
    }, 400);
    if (this.__PROPreloadRequired) { // Otherwise Pexip doesn't release the camera and webcam light stays on
      window.location.reload();
    }
  }

  /**
   * Applies the branding if it is available
   */
  applyBrandingIfAvailable() {
    if (this.authUser && this.authUser.meeting_room) {
      this.__MIXINapplyBranding(this.authUser.meeting_room.brand);
    }
  }

  /**
   *  Show the user the welcome page
   *  @param {any} e
   */
  showWelcomePage(e: any) {
    e.preventDefault();

    this.webrtcAlias = this.webrtcAlias.replaceAll(/\s/g, '');

    this.$router.push({
      name: 'welcome-guest',
      params: {
        webrtcAlias: this.webrtcAlias,
        previousRoute: {
          name: 'join-meeting',
          header: 'Join meeting',
          params: {},
        } as any,
      },
    });
  }

  /**
   * Gets whether the user is logged in or not
   */
  get isLoggedIn() {
    return authStore.__GETTERisUserLoggedIn;
  }

  /**
   * Get the logged in user instance if it exists
   */
  get authUser() {
    return userStore.profileData;
  }

  /**
   * Evaluates whether the error is the VMR not being found
   */
  get notFoundError() {
    return this.__PROPerror === 'Meeting not found';
  }

  /**
   * Getter to determine whether side image should be shown
   */
  get showRightSideImage() {
    return !this.isLoggedIn || (this.authUser && this.authUser.meeting_room.brand);
  }
}
