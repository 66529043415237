import Vue from 'vue';
import Component from 'vue-class-component';
import {Emit} from 'vue-property-decorator';
import {brandingStore} from '@/store/__STORE_branding';

@Component
/**
 * Branding Mixin for methods to enable branding behaviour
 */
export class Branding extends Vue {
    defaultBkImage = require('@/assets/img/SV-rebrand-hero.png');
    backImageStyle = '';

    /**
     *  Fires for setting or clearing branding
     * @param {any} brand - the brand to be set | null
     * @return {any} brand - the brand to be set | null
     */
    @Emit('set-room-brand')
    __EMITsetBranding(brand: any) {
      return brand;
    }

    /**
     * Applies the branding for any user
     * @param {any} brand
     */
    __MIXINapplyBranding(brand: any) {
      this.__EMITsetBranding(brand);
      brandingStore.setLogo({
        logo: brand.logo,
        level: brand.level,
      });
      brandingStore.setGuestWelcomeMessage(brand.guest_welcome_message);
      const bkImage = (brand.hero_image ? brand.hero_image : this.defaultBkImage);
      this.backImageStyle = `background-image: url(${bkImage})`;

      this.__MIXINchangeFavicon(brand);
    }

    /**
     *  Clears and disables all branding
     */
    __MIXINdisableBranding() {
      this.__EMITsetBranding(null);
    }

    /**
     * Update the favicon for branding
     * @param {any} brand
     */
    __MIXINchangeFavicon(brand: any) {
      const link = document.createElement('link');
      const oldLink = document.getElementById('dynamic-favicon');
      link.id = 'dynamic-favicon';
      link.rel = 'shortcut icon';
      if (brand.favicon) {
        link.href = brand.favicon;
      } else {
        link.href = '';
      }
      if (oldLink) {
        document.head.removeChild(oldLink);
      }
      document.head.appendChild(link);
    }
}
